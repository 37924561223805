// TODO: This needs some serious cleanup.
// TODO: Convert sizing to EMs and adjust shadows.
.toggle {
  --height: 3.5em;
  --width: calc(var(--height) * 1.62);
  --distance: calc(var(--height) * 0.62);
  --radius: var(--height);

  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
  user-select: none;

  span {
    box-sizing: content-box;
    background: #999;
    height: var(--height);
    width: var(--width);
    display: block;
    border-radius: var(--radius);

    border: 3px solid #444;
    position: relative;
    // overflow: hidden;

    &::before {
      --bg-width: calc(100% + var(--height) * 0.5);
      --offset: calc(var(--height) * -0.5);

      background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent),
        linear-gradient(90deg, lime 50%, #777 50%);
      background-position: 0 0, var(--offset) 0;
      background-size: var(--bg-width) var(--height),
        var(--bg-width) var(--height);
      background-repeat: no-repeat, no-repeat;

      height: 100%;
      width: var(--width);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      box-shadow: inset 2px 2px 4px var(--shade);
      border-radius: var(--radius);
      // Inset shadow moes too, so we transition background position, not translate.
      transition: background-position ease-out 100ms;
    }

    &::after {
      border: 1px solid rgba(0, 0, 0, 0.4);
      display: block;
      content: '';
      height: 100%;
      width: var(--height);
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.3)) #999;
      border-radius: var(--radius);
      transition: translate ease-out 100ms;
      z-index: 1;
      position: relative;
      box-shadow: 0 2px 4px var(--shade);
      translate: 0 0;
    }
  }

  input:checked ~ span::before {
    background-position: 0 0, 0 0;
  }

  input:checked ~ span::after {
    translate: var(--distance) 0;
  }

  input[type='checkbox'],
  input[type='radio'] {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }
}
