@keyframes meter {
  0% {
    rotate: -90deg;
  }
  25%,
  30% {
    rotate: -80deg;
  }
  50% {
    rotate: 0;
  }
  60% {
    rotate: 60deg;
  }
  65% {
    rotate: 45deg;
  }
  75% {
    rotate: 50deg;
  }
  80% {
    rotate: 75deg;
  }
  85% {
    rotate: 50deg;
  }
  90% {
    rotate: 75deg;
  }
  95% {
    rotate: 50deg;
  }
  100% {
    rotate: 80deg;
  }
}
.meter {
  --panel-color: rgb(210, 198, 140);
  --unit-color: rgba(137, 13, 13, 0.6);
  --needle-color: #c00;
  --needle-shadow: rgba(69, 65, 5, 0.6);
  --border-shadow: rgba(0, 0, 0, 0.8);
  --border-highlight: rgba(255, 255, 255, 0.3);
  --light: rgba(255, 255, 255, 0.6);

  width: max(5em, 12vw);
  aspect-ratio: 16/9;
  background: center 115%
    radial-gradient(ellipse at bottom, var(--light), transparent)
    var(--panel-color);
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 0.3em;
  box-shadow: inset 0.1em 0.1em 0.2em var(--needle-shadow);
  overflow: hidden;
  border-width: 0.05em;
  border-style: solid;
  border-color: var(--border-shadow) var(--border-highlight)
    var(--border-highlight) var(--border-shadow);

  &::after {
    content: '';
    background: linear-gradient(-15deg, var(--needle-shadow), transparent)
      var(--panel-color);
    width: 25%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    z-index: 1;
    translate: 0 50%;
    box-shadow: 0.1em 0.1em 0.2em var(--needle-shadow);
  }

  &::before {
    width: 0.05em;
    height: 75%;
    content: '';
    position: absolute;
    bottom: 0;
    background: var(--needle-color);
    translate: 0 10%;
    transform-origin: 50% 100%;
    animation: 2000ms ease-in-out infinite alternate-reverse meter;
    box-shadow: 0.1em 0.1em 0.2em var(--needle-shadow);
    border-left: 0.01em solid var(--border-highlight);
  }
}

.marker {
  color: var(--unit-color);
  rotate: var(--rotation, 0);
  transform-origin: 50% 110%;
  height: 75%;

  width: auto;
  font-size: 0.35em;
  position: absolute;
  line-height: 1;
  text-align: center;
  bottom: 0;
  display: flex;
  justify-content: center;

  &::before {
    content: '';
    border-left: 1px solid var(--unit-color);
    height: 20%;
    width: 100%;
    translate: 50% 120%;
    position: absolute;
  }
}
