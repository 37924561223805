.menu {
  --duration: 250ms;
  --delay: calc(var(--duration) - 5ms);
  --height: 3.5em;
  --bar-height: 0.5em;
  --scoot: calc(var(--height) / 2 - var(--bar-height) / 2);

  -webkit-tap-highlight-color: transparent;
  background: linear-gradient(
      currentColor var(--bar-height),
      transparent var(--bar-height)
    )
    no-repeat 0 var(--scoot) transparent;
  border-style: none;
  color: currentColor;
  cursor: pointer;
  font-size: 1em;
  height: var(--height);
  margin: 0;
  padding: 0;
  position: relative;
  rotate: 0deg;
  transition: rotate var(--duration);
  user-select: none;
  width: var(--height);

  &:before,
  &:after {
    content: '';
    background: currentColor;
    height: var(--bar-height);
    width: 100%;
    translate: 0 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: rotate var(--duration), translate var(--duration), opacity 5ms;
  }

  &:after {
    bottom: 0;
    top: auto;
  }

  &[data-open] {
    rotate: 45deg;

    &::before {
      rotate: 90deg;
      translate: 0 var(--scoot);
    }

    &:after {
      translate: 0 calc(var(--scoot) * -1);
      opacity: 0;
      transition-delay: 0ms, 0ms, var(--delay);
    }
  }
}
