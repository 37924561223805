::cue {
  font: 1.6rem/2.5rem 'Poppins', verdana, sans-serif;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.8);
}

::cue(v[voice='mary']) {
  color: hsl(2, 75%, 50%);
}

::cue(v[voice='jane']) {
  color: hsl(44, 75%, 50%);
}

::cue(v[voice='michael']) {
  color: hsl(91, 75%, 50%);
}

::cue(v[voice='bert']) {
  color: hsl(189, 75%, 50%);
}

::cue(.sigh) {
  background-color: transparent;
  font-size: 2.5rem;
  font-style: italic;
  font-weight: 900;
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 1);
}
