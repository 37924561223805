button.fuse {
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  background: radial-gradient(hsl(240, 34%, 66%), hsl(240, 34%, 15%)) 50% /
    calc(100% + 12px) calc(100% + 12px) no-repeat #999;
  border-radius: 100%;
  border-style: none;
  border: 1px solid var(--shade);
  box-shadow: 0 0 0 var(--tint);
  cursor: pointer;
  display: flex;
  font-size: 1em;
  justify-content: center;
  outline: none;
  padding: 5px;
  transition: box-shadow 350ms 50ms ease-in;
  user-select: none;

  &[data-clicked] {
    transition-delay: 450ms;
    box-shadow: 0 0 15px var(--base-color, #fff);
  }

  &::after {
    content: '';
    display: none;
    border: 0.75em solid var(--shade);
    position: absolute;
    border-radius: 100%;
    z-index: 0;
  }
}

.fuse-svg {
  --len: 1030;
  --offset: 1025;
  --stroke: var(--base-color, #fff);
  --fill: none;
  --width: 4em;

  width: var(--width);
  aspect-ratio: 1/1;
  rotate: 90deg;
  position: relative;
  z-index: 1;

  path {
    fill: var(--fill);
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}

.fuse-track-path {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 30px;
  // stroke-dashoffset: 70px;
  stroke-dasharray: var(--len);
}

.fuse-path {
  stroke-width: 40px;
  transition: stroke-dashoffset 550ms ease-out;
  stroke-dasharray: var(--len);
  stroke-dashoffset: var(--offset);
  stroke: var(--stroke);

  [data-clicked] & {
    stroke-dashoffset: 0;
  }
}
