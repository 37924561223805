@import '_sass-vars';

.gif-gallery {
  position: relative;
  width: 100%;

  p {
    margin-bottom: 1em;
  }
}

.gif-gallery-image {
  display: grid;
  position: relative;
  height: var(--height, auto);
  width: var(--width, auto);
  margin: 0 0 var(--1fr);

  img {
    max-width: 100%;
    height: auto;
    aspect-ratio: attr(width) / attr(height);
  }

  @media screen and (min-width: $media-md) {
    margin: 0;

    img {
      position: relative;
      top: var(--offsetY, 0);
      left: var(--offsetX, 0);
      width: var(--img-width, 100%);
    }

    &::before,
    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::before {
      background: var(--frame) 0 0 / 100% auto no-repeat transparent;

      -webkit-mask: var(--mask) 0 0 / 100% auto no-repeat;
      mask: var(--mask) 0 0 / 100% auto no-repeat;
      z-index: 1;
    }

    &::after {
      background: var(--mask, none) 0 0 /100% auto no-repeat;

      top: 2px;
      left: 2px;
      opacity: 0.8;
      filter: blur(4px);
      z-index: 0;
    }
  }
}

.gif-jake {
  @media screen and (min-width: $media-md) {
    --offsetX: 61px;
    --offsetY: 61px;
    --width: 500px;
    --height: 333px;
    --img-width: 77%;
    --mask: url(../i/jake-frame-mask.png);
    --frame: url(../i/jake-frame.jpg);
  }

  @media screen and (min-width: $media-lg) {
    --offsetX: 80px;
    --offsetY: 80px;
    --width: 659px;
    --height: 439px;
  }
}

.gif-ohnoes {
  @media screen and (min-width: $media-md) {
    --width: 203px;
    --height: 206px;
    --offsetX: 50px;
    --offsetY: 56px;
    --img-width: 50%;
    --mask: url(../i/square-frame-mask@1x.png);
    --frame: url(../i/square-frame@1x.jpg);
  }
}

.gif-oprah {
  @media screen and (min-width: $media-md) {
    --width: 398px;
    --height: 268px;
    --offsetX: 53px;
    --offsetY: 51px;
    --img-width: 75%;
    --mask: url(../i/oprah-frame-mask@1x.png);
    --frame: url(../i/oprah-frame@1x.jpg);
  }
}

@media screen and (min-width: $media-md) {
  .gif-ohnoes {
    position: absolute;
    bottom: calc(var(--1fr) + 360px);
    right: 0;
  }

  .gif-oprah {
    margin-bottom: var(--1fr);
  }

  .gif-jake {
    justify-self: center;
  }
}

@media screen and (min-width: $media-lg) {
  .gif-gallery {
    padding-bottom: 90px;
  }

  .gif-jake {
    align-self: center;
    justify-self: center;
    translate: -35% 40px;
  }

  .gif-oprah {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    margin: auto;
    translate: 90% 0;
  }

  .gif-ohnoes {
    position: absolute;
    right: 0;
    bottom: 315px;
    left: 0;

    margin: auto;
    translate: 177% 0;
  }
}
