@keyframes shine {
  10%,
  100% {
    background-position: 200% 0;
  }
}

@keyframes wobble-horiz {
  0%,
  100% {
    translate: -75% 0;
  }
  50% {
    translate: 75% 0;
  }
}

@keyframes wobble-vert {
  0%,
  100% {
    translate: 0 10%;
  }
  50% {
    translate: 0 -25%;
  }
}

.coinshine-container {
  animation: 2.5s ease-in-out infinite wobble-vert;
}

.coinshine {
  --bg: rgb(255, 204, 0);
  --accent: rgb(224, 129, 5);
  --shadow: rgba(0, 0, 0, 0.5);
  --highlight: rgba(255, 255, 255, 0.7);

  align-items: center;
  animation: 5s ease-in-out infinite wobble-horiz;
  background-color: var(--bg);
  background-image: linear-gradient(150deg, transparent, var(--accent));
  border-radius: 50%;
  box-shadow: 2px 2px 0 var(--bg);
  color: var(--accent);
  display: flex;
  height: var(--height, 3em);
  justify-content: center;
  margin: 3px 0 0;
  position: relative;
  text-shadow: -1px -1px 0 var(--shadow), 1px 1px 0 var(--highlight);
  translate: 0 -3px;
  width: var(--width, 3em);

  &:before,
  &:after {
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:before {
    border: 0.33em solid transparent;
    box-shadow: inset 2px 2px 2px var(--shadow),
      inset -1px -1px 2px var(--highlight), 2px 2px 0 var(--shadow);
    border-radius: 50%;
  }

  &:after {
    animation: 3s ease-out infinite shine;
    background-image: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.9),
      transparent
    );
    background-position: -100% 0;
    background-repeat: no-repeat;
    background-size: 50% 100%;
    border-radius: 50%;
    rotate: -20deg;
  }
}
