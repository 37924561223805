:root[data-ringing] body {
  overflow-x: hidden;
}

.bell {
  --timing: 150ms;

  align-items: center;
  cursor: grabbing;
  display: flex;
  font-size: 4em;
  justify-content: center;
  line-height: 1;
  position: relative;
  rotate: 0deg;
  text-align: center;
  transition: rotate var(--timing) ease-in-out;
  user-select: none;

  &::after {
    --size: 450px;
    --neg: transparent;
    --ring: rgba(255, 255, 255, 1);

    background-image: radial-gradient(
      var(--neg) 20px,
      var(--ring) 20px,
      var(--ring) 25px,
      var(--neg) 25px,
      var(--neg) 30px,
      var(--ring) 30px,
      var(--ring) 35px,
      var(--neg) 35px,
      var(--neg) 50px,
      var(--ring) 50px,
      var(--ring) 55px,
      var(--neg) 55px,
      var(--neg) 80px,
      var(--ring) 80px,
      var(--ring) 85px,
      var(--neg) 85px,
      var(--neg) 130px,
      var(--ring) 130px,
      var(--ring) 135px,
      var(--neg) 135px,
      var(--neg) 210px,
      var(--ring) 210px,
      var(--ring) 215px,
      var(--neg) 215px
    );
    border-radius: 100%;
    content: '';
    opacity: 1;
    padding-bottom: var(--size);
    pointer-events: none;
    position: absolute;
    scale: 0;
    width: var(--size);
    z-index: -1;
  }

  &[data-ringing] {
    rotate: 65deg;

    &::after {
      transition: scale 350ms ease-out, opacity 350ms ease-out;
      scale: 2;
      opacity: 0;
    }
  }
}

.ding {
  --ding1: hsl(188, 100%, 15%);
  --ding2: hsl(188, 100%, 50%);

  position: absolute;
  font: 900 5rem 'Barlow Condensed', Impact, sans-serif;
  text-transform: uppercase;
  // rotate: 25deg;
  translate: 0 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 500ms ease-out, translate 250ms 500ms ease-out;

  background-color: #fff;
  background-image: linear-gradient(var(--ding1), var(--ding2));
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent;
  -webkit-text-stroke: 3px #fff;
  text-stroke: 3px #fff;

  [data-ringing] + & {
    opacity: 1;
    translate: 75% -75%;
    transition: opacity 500ms ease-out, translate 500ms ease-out;
  }
}

.toggle-audio {
  background-color: transparent;
  border-style: none;
  bottom: 0;
  cursor: pointer;
  font-size: 2em;
  line-height: 1;
  padding: 0.5rem;
  position: absolute;
  rotate: 15deg;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  translate: 120% 20%;
}
