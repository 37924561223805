@import '_sass-vars';

@keyframes static {
  0% {
    background-position: 0px 0px;
  }
  25% {
    background-position: 128px 128px;
  }
  50% {
    background-position: -128px 0px;
  }
  100% {
    background-position: 128px -128px;
  }
}

.video-container {
  align-items: center;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
  position: relative;

  &::after {
    --blur: 5px;

    content: '';
    height: 1.5rem;
    width: 85%;
    border-radius: 100%;
    background-color: var(--shade);
    position: absolute;
    bottom: 0;
    box-shadow: 0 0 var(--blur) var(--shade);
  }

  @media screen and (min-width: $media-md) {
    width: 538px;
    height: 351px;
  }
}

.tv-overlay {
  display: none;
  -webkit-mask: url(../i/tv-mask.png) 0 0/100% auto no-repeat;
  mask: url(../i/tv-mask.png) 0 0/100% auto no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  pointer-events: none;

  @media screen and (min-width: $media-md) {
    width: 538px;
    display: block;
  }
}

.broadcast {
  background: url(../i/static.png) repeat 0 0/256px 256px var(--base-background);
  width: auto;
  cursor: pointer;
  animation: 0.25s steps(4) infinite static;
  width: auto;

  border-radius: var(--rad);
  border: 2px solid currentColor;
  max-width: 320px;

  @media screen and (min-width: $media-md) {
    border-radius: 0;
    border-style: none;
    height: 240px;
    left: 50px;
    margin-right: 150px;
    position: absolute;
    top: 52px;
    z-index: 1;
  }
}

.playstate {
  --icon-color: rgba(255, 255, 255, 0.8);
  --tp: transparent;

  align-items: center;
  display: flex;
  height: 240px;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  width: 320px;
  z-index: 2;

  @media screen and (min-width: $media-md) {
    left: 50px;
    top: 52px;
  }

  &::before,
  &::after {
    border-color: var(--icon-color);
    border-style: solid;
    border-width: 0 0.75em;
    box-sizing: content-box;
    content: '';
    display: block;
    font-size: 2rem;
    height: 2em;
    opacity: 0;
    position: absolute;
    scale: 2;
    transition: scale 150ms ease-in, opacity 150ms ease-in;
    width: 0.5em;
  }

  &::after {
    opacity: 0;
  }

  [data-paused] + &::before {
    opacity: 1;
    scale: 1;
  }

  [data-seeking] + &::before,
  [data-seeking] + &::after {
    scale: 1;
    opacity: 1;
    border-width: 0.75em;
    border-style: solid;
    border-color: var(--tp) var(--tp) var(--tp) var(--icon-color);
    height: 0;
    width: 0;
    display: block;
  }

  [data-seeking] + &::after {
    translate: 55% 0;
  }

  [data-seeking='back'] + &::before {
    rotate: 180deg;
  }

  [data-seeking='back'] + &::after {
    translate: -55% 0;
    rotate: 180deg;
  }
}
